




import LabApproximatingAvogadrosNumber from '../simulations/LabApproximatingAvogadrosNumber.vue';
import LabTask from '@/tasks/components/dynamic-questions/labs/LabTask.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'ApproximatingAvogadrosNumberSIM',
  components: {LabApproximatingAvogadrosNumber, LabTask},
  mixins: [DynamicQuestionMixin()],
});
